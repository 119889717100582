<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <div class="mx-1">
          <label style="color: black; font-size: 0.85rem" class="pl-2"
            >Status Serviço</label
          >
          <br />
          <el-select
            filterable
            placeholder="Status do Serviço"
            size="small"
            clearable
            v-model="filtro.executado"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="status in statusServico"
              :value="status.id"
              :label="status.nome"
              :key="status.id"
            ></el-option>
          </el-select>
        </div>
        <div class="mx-1">
          <label style="color: black; font-size: 0.85rem" class="pl-2"
            >Colaborador</label
          >
          <br />
          <el-select
            filterable
            clearable
            remote
            size="small"
            :remote-method="filtroColaborador"
            placeholder="Pesquise"
            loading-text="Buscando..."
            :loading="loading"
            v-model="filtro.colaborador"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="colaborador in colaboradores"
              :value="colaborador.id"
              :label="colaborador.nome"
              :key="colaborador.id"
            ></el-option>
          </el-select>
        </div>
        <div class="mx-1">
          <label style="color: black; font-size: 0.85rem" class="pl-2"
            >Rota</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            placeholder="Pesquise"
            v-model="filtro.rota"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="rota in rotas"
              :value="rota.id"
              :label="rota.nome"
              :key="rota.id"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getRetornos()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div id="retornosOS" class="cardTable mb-1 vs-con-loading__container">
      <div v-if="retornosOS.length == 0">
        <div class="pt-20 scroll-retornos-os con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Retorno
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1">
        <VuePerfectScrollbar class="scroll-retornos-os" :settings="settings">
          <vs-table stripe max-items="10" pagination :data="retornosOS">
            <template slot="thead">
              <vs-th sort-key="id_contrato" style="width: 5%" id="center"
                >Contrato</vs-th
              >
              <vs-th sort-key="id" style="width: 5%" id="center">OS</vs-th>
              <vs-th sort-key="id_cliente" style="width: 18%">Cliente</vs-th>
              <vs-th sort-key="id_colaborador" style="width: 18%"
                >Técnico</vs-th
              >
              <vs-th style="width: 8%" id="center">Tipo Servico</vs-th>
              <vs-th style="width: 12%" id="center">Data / Horário</vs-th>
              <vs-th style="width: 12%" id="center">Data Inicio</vs-th>
              <vs-th style="width: 12%" id="center">Data Fim</vs-th>
              <vs-th style="width: 20%" id="center">Observação</vs-th>
              <vs-th style="width: 3%">Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                :key="index"
                v-for="(row, index) in data"
                :style="
                  row.executado == 1
                    ? 'color: green;background-color:#e8fce8 !important;'
                    : row.executado == 2
                    ? 'color:#dd3522;background-color: #f5efef !important'
                    : ''
                "
              >
                <vs-td :data="row.id_contrato" class="text-center">
                  <a
                    target="_blank"
                    :href="'contrato?id=' + row.id_contrato"
                    style="color: darkblue; cursor: pointer; font-size: 12px"
                  >
                    <b>{{ row.id_contrato }} </b>
                  </a>
                </vs-td>
                <vs-td
                  :data="row.id"
                  class="text-center"
                  style="font-size: 12px"
                >
                  <b>{{ row.id }}</b>
                </vs-td>

                <vs-td :data="row.id_cliente" style="font-size: 12px">
                  <VisualizarCliente
                    @update="close()"
                    :id_cliente="row.id_cliente"
                    :nomeCliente="row.nomeCliente"
                  />
                </vs-td>
                <vs-td style="font-size: 12px">
                  <b>{{ row.colaborador }}</b>
                </vs-td>

                <vs-td class="text-center" style="font-size: 12px">
                  <b>{{ row.tipoServico }}</b>
                </vs-td>
                <vs-td class="text-center" style="font-size: 12px">
                  <b>{{ row.data }}</b>
                </vs-td>
                <vs-td class="text-center" style="font-size: 12px">
                  <b>{{ row.data_inicio ? row.data_inicio : "--" }}</b>
                </vs-td>
                <vs-td class="text-center" style="font-size: 12px">
                  <b>{{ row.data_fim ? row.data_fim : "--" }}</b>
                </vs-td>
                <vs-td class="text-center" style="font-size: 12px">
                  <obs v-if="row.executado == 1" :os="row"></obs>
                </vs-td>
                <vs-td class="text-center">
                  <vs-row vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col
                      vs-w="12"
                      vs-align="center"
                      vs-justify="center"
                      v-if="row.executado != 2"
                    >
                      <vs-button
                        class="px-0 py-0 m-0"
                        icon="remove_red_eye"
                        :disabled="!(row.executado == 1)"
                        color="success"
                        @click="redirectBlank(row)"
                        size="small"
                        type="filled"
                      ></vs-button>
                      <!-- </a> -->
                    </vs-col>
                    <vs-col
                      vs-w="12"
                      vs-align="center"
                      vs-justify="center"
                      v-else
                    >
                      <OsNaoExec :os="row" />
                    </vs-col>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import obs from "./obsModal"
import components from "@/components/default/exports.js"
import OsNaoExec from "./../agenda-servicos/os/components/osNaoExec.vue"
import VisualizarCliente from "./../cliente/visualizar.vue"
export default {
  data () {
    return {
      retornosOS: [],
      colaboradores: [],
      tiporetornosOS: [],
      estagios: [],
      statusServico: [
        { id: 1, nome: "Executado" },
        { id: 0, nome: "Não Executado" },
      ],
      loading: false,
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
      },
      rotas: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      relatorioUrl: ""
    }
  },
  methods: {
    async getRetornos () {
      await this.$vs.loading({ container: "#retornosOS", scale: 0.6 })
      try {
        const res = await this.$http.post(`getRetornosOS`, this.filtro)
        this.retornosOS = res.retornosOS
        this.relatorioUrl = res.relatorioUrl

      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#retornosOS > .con-vs-loading")
      }
    },
    async getColaboradoresByQuery (query) {
      try {
        this.colaboradores = await this.$http.post(`getColaboradorByQuery`, {
          query: query,
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroColaborador (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getColaboradoresByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
    async redirectBlank (os) {
      const link = `${this.relatorioUrl}/?os=${os.id}`
      var a = document.createElement("a")
      a.target = "_blank"
      a.href = link
      a.click()
    },
    async mountData () {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split("T")[0]
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0]
      this.filtro.dataDe = firstDay
      this.filtro.dataAte = lastDay
    },
    async getRotas () {
      try {
        this.rotas = await this.$http.get(`rota`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEstagios () {
      try {
        this.estagios = await this.$http.get(`contrato_estagio`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    await this.mountData()
    await this.getRetornos()
    await this.getRotas()
  },
  components: { ...components, VisualizarCliente, OsNaoExec, obs },
};
</script>
<style lang="scss">
.scroll-retornos-os {
  height: 76vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
