<template>
  <div>
    <div @click="openModal" style="cursor: pointer; font-size: 14px">
      {{ os.obs }} / {{ os.obs_interna }} / {{ os.obs_proximo_servico }}
    </div>

    <vs-popup :title="'SERVIÇO  N° ' + os.id" :active.sync="osModalNaoExec">
      <vs-row
        vs-type="flex"
        vs-align="center"
        id="remarcar"
        class="vs-con-loading__container"
      >
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <div class="w-full p-0">
            <h6>
              Observação:
              <h4>{{ os.obs ? os.obs : "..." }}</h4>
            </h6>
          </div>
        </vs-col>

        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <div class="w-full p-0">
            <h6>
              Observação Prox. Serviço:
              <h4>
                {{ os.obs_proximo_servico ? os.obs_proximo_servico : "..." }}
              </h4>
            </h6>
          </div>
        </vs-col>

        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <div class="w-full p-0">
            <h6>
              Observação interna:
              <h4>{{ os.obs_interna ? os.obs_interna : "..." }}</h4>
            </h6>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    os: {
      required: true,
    },
  },
  data() {
    return {
      osModalNaoExec: false,
      show: false,
    };
  },
  methods: {
    async openModal() {
      this.osModalNaoExec = true;
    },
    async getNaoExecData() {
      try {
        this.os = await this.$http.post("/getNaoExecData", {
          id: this.os.id,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async close() {
      this.osModalNaoExec = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
